@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

body,
h1,
h2,
h3,
h4,
h5,
h6,
td,
li,
th {

  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}